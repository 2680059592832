
export default {
    name: "RegionHeader",
    components: {
        HauzenbergHeader: () =>
            import(`@/components/region/hauzenberg/RegionHeader`),
        WeissenburggunzenhausenHeader: () =>
            import(`@/components/region/weissenburg/RegionHeader`),
        AltmuehlfrankenHeader: () =>
            import(`@/components/region/altmuehlfranken/RegionHeader`),
        DeggendorfHeader: () =>
            import("@/components/region/deggendorf/RegionHeader"),
        InnsalzachHeader: () =>
            import("@/components/region/inn-salzach/RegionHeader"),
        FreyunggrafenauHeader: () =>
            import("@/components/region/freyung-grafenau/RegionHeader"),
        PassauHeader: () => import("@/components/region/passau/RegionHeader"),
    },
    props: {
        /** Full Region Object */
        region: {
            type: Object,
            required: true,
        },
        showCarousel: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        customHeaderSlugs: [
            "hauzenberg",
            "weissenburg-gunzenhausen",
            "altmuehlfranken",
            "deggendorf",
            "inn-salzach",
            "freyung-grafenau",
            "passau",
        ],
    }),
    computed: {
        hasCustomHeader() {
            return this.customHeaderSlugs.includes(this.regionSlug)
        },
        regionSlug() {
            return this.region?.slug
        },
        componentName() {
            let regionSlug = this.regionSlug.replace(/-/g, "")
            const name =
                regionSlug.charAt(0).toUpperCase() + regionSlug.slice(1)
            return name + "Header"
        },
    },
}
